import React from 'react'
import styled from "styled-components"
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'

const ContactSection = styled.section`
  margin-bottom: 110px;

  .row-two-col {
    align-items: flex-start;
    row-gap: 0;

    @media(min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .btn {
    margin-bottom: 40px;
  }

  p {
    color: var(--gray);
    margin-bottom: 40px;
  }

  .gatsby-image-wrapper {
    margin-bottom: 30px;
    max-width: 550px;
    width: 100%;
    height: auto;
  }

  #contact-form-container {
    .ml-form-embedContainer {
      .ml-form-embedWrapper {
        max-width: 100% !important;

        .ml-form-embedBody {
          padding: 0 !important;

          .ml-form-embedContent {
            margin-bottom: 0 !important;
          }

          .ml-form-formContent {
            display: flex !important;
            flex-direction: column !important;
            column-gap: 20px !important;
            row-gap: 30px !important;
            margin: 0 0 30px 0 !important;
          }

          .ml-form-fieldRow {
            margin-bottom: 0 !important;

            .ml-field-group label {
              margin-bottom: 10px !important;
              line-height: 1;
            }

            input {
              background-color: rgba(0 0 0 / 70%) !important;

              &:hover,
              &:focus,
              &:focus-visible {
                border: 1px solid transparent !important;
                background: linear-gradient(var(--black),var(--black)) padding-box,linear-gradient(to right,var(--blue),var(--pink)) border-box !important;
                outline: none;
              }
            }

            textarea {
              height: 100px !important;

              &:hover,
              &:focus,
              &:focus-visible {
                border: 1px solid transparent !important;
                background: linear-gradient(var(--black),var(--black)) padding-box,linear-gradient(to right,var(--blue),var(--pink)) border-box !important;
                outline: none;
              }
            }
          }
        }

        .ml-form-recaptcha {
          margin-bottom: 30px !important;
        }

        .ml-form-embedSubmit {
          margin-bottom: 0 !important;
    
          button[type="submit"] {
            display: inline-block !important;
            max-width: 220px !important;
            border: 1px solid var(--pink) !important;
  
            &:hover {
              border: 1px solid var(--white) !important;
              color: var(--white) !important;
            }
          }
        }

        .ml-form-successBody {
          padding: 0 !important;

          .ml-form-successContent {
            color: var(--white) !important;
            background-color: var(--pink) !important;
            padding: 20px !important;

            h4 {
              color: var(--white) !important;
            }

            p {
              color: var(--white) !important;
              font-size: 18px !important;
            }
          }
        }
      }
    }
  }
`

// markup
const ContactPage = (props) => {
  return (
    <main>
      {props.data.pageTitleData.edges.map(({ node }) => (
        <PageTitle key={node.id} title={node.pageTitle} paragraph={node.pageTitleParagraph.pageTitleParagraph} />
      ))}
      {props.data.ContactData.edges.map(({ node }) => (
        <ContactSection key={node.id}>
          <div className="container">
            <div className="row-two-col">
              <div className="col-1">
                <h2>{node.scheduleH2}</h2>
                <p>{node.scheduleParagraph.scheduleParagraph}</p>
                <a href={node.scheduleButtonUrl} className="btn btn-pink" target="_blank" rel="noopener noreferrer">{node.scheduleButtonText}</a>
                <p>{node.scheduleByeParagraph.scheduleByeParagraph}</p>
                {node.schedulePhoto && (
                  <GatsbyImage image={node.schedulePhoto.gatsbyImageData} alt={node.schedulePhoto.title} width={550} />
                )}
              </div>
              <div className="col-2">
                <h2>{node.contactH2}</h2>
                <div id="contact-form-container">
                  <div className="ml-embedded" data-form="T1rth5"></div>
                </div>
              </div>
            </div>
          </div>
        </ContactSection>
      ))}
    </main>
  )
}

export default ContactPage

export const Head = (seo) => {
  const seoData = seo.data.pageTitleData
  return (
    <>
      {seoData.edges.map(({ node }) => (
      <Seo key={node.id} title={node.seoTitle} description={node.seoDescription.seoDescription} image={node.seoImage.url} />
      ))}
    </>
  )
}

export const AboutContent = graphql`
  {
    pageTitleData: allContentfulContactUsPage(filter: {pageTitle: {ne: "Dummy Contact Page"}}) {
      edges {
        node {
          id
          pageTitle
          pageTitleParagraph {
            pageTitleParagraph
          }
          seoTitle
          seoDescription {
            seoDescription
          }
          seoImage {
            url
          }
        }
      }
    }
    ContactData: allContentfulContactUsPage(filter: {pageTitle: {ne: "Dummy Contact Page"}}) {
      edges {
        node {
          id
          scheduleH2
          scheduleParagraph {
            scheduleParagraph
          }
          scheduleButtonText
          scheduleButtonUrl
          scheduleByeParagraph {
            scheduleByeParagraph
          }
          schedulePhoto {
            gatsbyImageData(width: 800, placeholder: BLURRED)
            title
          }
          contactH2
        }
      }
    }
  }
`;